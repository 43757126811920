'use client'
import NavigateLeftIcon from '@/app/icons/navigate-left'
import NavigateRightIcon from '@/app/icons/navigate-right'
import { cn } from '@/util/cn'
import { Children, ReactNode, useEffect, useState } from 'react'

type Props = {
    title?: ReactNode
    children: ReactNode
    onChange?: (index: number) => void
    maxBlocks?: number
    startIndex?: number
    className?: string
    onDone?: (offset: number) => void
    itemHeight?: number
    gap?: number
}

const PaginationCarousel = ({
    title = '',
    children,
    className,
    maxBlocks = 4,
    startIndex = 0,
    onChange,
    onDone,
    itemHeight,
    gap = 0,
}: Props) => {
    const [index, setIndex] = useState(startIndex)
    const [showAnimation, setShowAnimation] = useState(false)
    const buttonClassName = 'bg-giphyLightCharcoal cursor-pointer px-2.5 h-5'
    const numChildren = Children.count(children)
    const isDone = index + maxBlocks >= numChildren
    useEffect(() => {
        if (isDone) {
            onDone?.(numChildren)
        }
    }, [isDone, onDone, numChildren])
    const stackLeft = numChildren - index < maxBlocks
    return (
        <div className={cn('flex flex-col gap-1', className)}>
            <div className={cn('flex justify-between')}>
                <div>{title}</div>
                <div className={cn('flex gap-1', numChildren < maxBlocks && 'hidden')}>
                    <button
                        className={cn(buttonClassName, index === 0 && 'pointer-events-none opacity-50')}
                        onClick={() => {
                            const nextIndex = index - maxBlocks
                            setIndex(nextIndex)
                            onChange?.(nextIndex)
                            setShowAnimation(true)
                        }}
                    >
                        <NavigateLeftIcon className="size-2.5" />
                    </button>
                    <button
                        className={cn(
                            buttonClassName,
                            index >= numChildren - maxBlocks && 'pointer-events-none opacity-50'
                        )}
                        onClick={() => {
                            if (index < numChildren - maxBlocks) {
                                const nextIndex = index + maxBlocks
                                setIndex(nextIndex)
                                onChange?.(nextIndex)
                                setShowAnimation(true)
                            }
                        }}
                    >
                        <NavigateRightIcon className="size-2.5" />
                    </button>
                </div>
            </div>
            <div
                className={cn(stackLeft ? 'grid' : 'flex justify-between')}
                key={`${index}`}
                style={{
                    // item height helps with CLS
                    height: itemHeight,
                    // spaces items more evenly from left to right if there are less than maxBlocks
                    gridTemplateColumns: `repeat(${maxBlocks}, 1fr)`,
                    // manually set the gap
                    gap,
                }}
            >
                {Children.toArray(children)
                    .slice(index, index + maxBlocks)
                    .map((item, index) => (
                        <div
                            key={index}
                            className={cn(showAnimation && 'animate-slide-up')}
                            style={{
                                animationDelay: `calc(${index + 1} * 0.05s)`,
                                animationTimingFunction: `cubic-bezier(0.77, 0, 0.175, 1)`,
                            }}
                            onAnimationEnd={() => {
                                if (index % maxBlocks === 0) {
                                    setShowAnimation(false)
                                }
                            }}
                        >
                            {item}
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default PaginationCarousel
